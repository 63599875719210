import React from 'react';
import {Jumbotron, Container, Button} from 'react-bootstrap';

import bgimage from '../images/galaxy.jpg';
import catimage from '../images/galaxycat.png';

function JumbotronComponent() {
  let wSize = window.innerWidth;
  let size;

  if(wSize<350){
    size = 550
  }else{
    size=wSize
  }

  const divStyle = {
    paddingTop: '150px',
    backgroundImage: `url(${bgimage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '90vh',
    fontFamily: 'Sigmar One'
  };

  const titleStyle = {
    fontSize: size/12,
    fontFamily: 'Mr Dafoe',
    textShadow: '2px 2px black',
  };

  const pStyle = {
    color :'rbg(176, 140, 42)',
    fontFamily: 'Lato',
    textShadow: '1px 1px black',
  };

  const spanStyle = {
    color: 'white',
  }

  return(
    <Jumbotron style={divStyle}>
      <h1 style = {titleStyle}>Cheshire Creations</h1>
      <h3>New Age Custom Made Websites</h3>
      <p style = {pStyle}>
      </p>
    </Jumbotron>
  )
}

export default JumbotronComponent;

import React from 'react';

import Navbar from '../sections/Navbar.js'
import Footer from '../sections/Footer.js';

import Jumbotron from '../components/Jumbotron.js'
import CardGroup from '../components/CardGroup.js'
import Card from '../components/Card.js';
import Map from '../components/Map.js';
import ContactForm from '../sections/ContactForm.js';

import About from '../sections/About.js';
import Services from '../sections/Services.js';

import BannerText from '../components/BannerText';

import Testimonials from '../sections/Testimonials.js';



function Home() {

  return (
    <>
    <div className="Home">
      <Navbar />
      <Jumbotron/>
      <About/>
      <BannerText text={"Make your online prescence known today!"}/>
      <Services/>
      <BannerText text={"Contact us for a quote on your project!"}/>
      <Testimonials/>
      <Map address="Austin, TX"/>
      <ContactForm />
      <Footer />
    </div>
    </>
  );
}

export default Home;

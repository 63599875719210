import React from 'react';
import {Container,Row,Col,Image} from 'react-bootstrap';
import CardGroup from '../components/CardGroup';

import bgImage from '../images/galaxyblue.jpg';
import image1 from '../images/CheshireLeft.png';
import designImage from '../images/webdesign.png';
import frontendImage from '../images/frontend.svg'
import backendImage from '../images/backend.png'

const services = [
  {
    title: "Web Design",
    body: "Wireframing, rapid protoyping, intuitive user interfaces and appealing design",
    image: designImage
  },
  {
    title: `Frontend Development`,
    body: "We use the newest cutting edge technology to give your site the modern feel it deserves",
    image: frontendImage
  },
  {
    title: "Backend Development",
    body: "Api development and integration, hosting, and database configuration",
    image: backendImage
  },

];

function Services() {

  const Styles = {
    backgroundAttachment: "fixed",
    minHeight: '500px',
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  const titleStyle = {
    textShadow: '2px 2px black',
  };

  const listStyle = {
    fontFamily: 'Lato',
    color: 'black',
  }

  return (
    <div className="Services" style={Styles}>
      <Container>
        <br/>
        <h1>Services</h1>
        <CardGroup services={services} className='cards'/>
        <br/>
      </Container>
    </div>
  );
}

export default Services;

import React from 'react';
import {Container ,Navbar, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap';
// import {Link} from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";


function NavbarComponent(){
  return(
    <nav className="navbar" role="navigation" aria-label="main navigation" >
      <div className="navbar-brand">
        <Link
          activeClass="active"
          className="navbar-item"
          to="Home"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <img src="cheshirecute.png" width="50" height="50" alt="Logo"/>
        </Link>
        <Link
          activeClass="active"
          className="navbar-item"
          to="Home"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <strong className="navbar-item">Cheshire Creations</strong>
        </Link>
      </div>

      <div className="navbar-start">
        <Link
          activeClass="active"
          className="navbar-item"
          to="About"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <strong className="navbar-item">About</strong>
        </Link>
        <Link
          activeClass="active"
          className="navbar-item"
          to="Services"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <strong className="navbar-item">Services</strong>
        </Link>
        <Link
          activeClass="active"
          className="navbar-item"
          to="Contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
        >
          <strong className="navbar-item">Contact</strong>
        </Link>
      </div>
    </nav>
  )
}

export default NavbarComponent;

import React from 'react';
import {Container,Col,Row} from 'react-bootstrap';
import axios from 'axios';
const config = require('../config.json');

class ContactForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      successMsg: '',
      name: '',
      phone: '',
      email: '',
      partySize: '1-5',
      message: ''
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);

    if(this.state.name===''){
      this.setState({ successMsg: 'Name Required'})
    }else if(this.state.phone===''){
      this.setState({successMsg:'Phone Number Required'})
    }else if(this.state.email===''){
      this.setState({successMsg:'Email Required'})
    }else if(this.state.message===''){
      this.setState({successMsg:'Message Required'})
    }else{
      axios.post(`${config.api.formURL}`, this.state);
      this.setState({
        successMsg: 'Message Sent',
        name: '',
        phone: '',
        email: '',
        message: ''
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render(){
    let BtnStyle = {
      backgroundColor: 'skyblue',
      color: 'white',
      borderStyle: 'none'
    }
    return(
      <>
      <Container className="Contact">
        <form style={{fontFamily: "Arial"}} onSubmit={this.handleSubmit}>
          <br/>
          <h1>Contact Us</h1>
          <br/>
          <Row>
            <Col>
              Name:
              <br/>
              <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder=" Name"/>
            </Col>
            <Col>
              Phone:
              <br/>
              <input type="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} placeholder=" ### ### ####"/>
            </Col>
            <Col>
              Email:
              <br/>
              <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder=" Email"/>
            </Col>
          </Row>
          <Row>
          <Col>
            <br/>
            Message:
            <textarea className="form-control" id="ContactMessage" name="message" rows="5" value={this.state.message} onChange={this.handleInputChange} placeholder="Message"></textarea>
          </Col>
          </Row>
          <br/>
          <button style={BtnStyle} type="button" onClick={this.handleSubmit}>Submit</button>
        </form>
        <span style={{color: 'red'}}>{this.state.successMsg}</span>
        <br/>
        <br/>
      </Container>
     </>
    )
  }

}

export default ContactForm;

import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';

function FooterComponent(){

  const FooterStyles = {
    color: 'rgb(14, 216, 227)'
  }
  const TextStyles = {
    fontSize: '12px'
  }

  return(
    <footer className="footer">
    <br/>
    <Container>
      <Row>
        <Col>
          <strong style={FooterStyles}>Cheshire Creations</strong>
          <br/>
          <div style={TextStyles}>
             Austin, TX
          </div>
        </Col>
      </Row>
      <Row>
        <Col >
          <strong style={FooterStyles}>Hours</strong>
          <br/>
          <div style={TextStyles}>
            <strong>Monday - Friday:	</strong> 9AM–5PM
          </div>
        </Col>

        <Col>
          <div>
            <strong style={FooterStyles}>Contact</strong>
            <br/>
            <div style={TextStyles}>
            <strong>Email: </strong>CheshireCreationsWebDev@gmail.com
            </div>
          </div>
        </Col>
      </Row>
      <br/>
      <div className="footer-copyright text-center" >© 2020 Copyright: <strong style={FooterStyles}>Cheshire Creations</strong></div>
      </Container>
    </footer>
  )
}

export default FooterComponent;

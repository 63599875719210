import React,{useEffect} from 'react';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';


import {Container,Row,Col,Image} from 'react-bootstrap';
import {Grid} from '@material-ui/core';

import bgImage from '../images/codebg2.jpg';

function Testimonials() {
  let wWidth = window.innerWidth;
  let paddingWid = Math.floor(wWidth/4)+'px';

  const Styles = {
    minHeight: '500px',
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: "fixed",
    backgroundSize: 'cover',
  }

  const QuoteStyle = {
    paddingTop: '10%',
    color: 'white',
    fontStyle: 'italic'
  }

  return (
    <div className="Testimonials" style={Styles}>
      <br/>
      <h1>Testimonials</h1>
      <FormatQuoteIcon/>
      <Row>
        <Col/>
        <Col>
        <h5>
        <blockquote style={QuoteStyle}>
        I was amazed by the quality of the work done with a very limited time frame. I would recommend Cheshire Creations to anyone who needs a website, no matter how big or small!
        </blockquote>
        </h5>
        <br/>
        <span><h4>- Small Business Owner</h4></span>
        </Col>
        <Col/>
      </Row>
      <br/>
    </div>
  );
}

export default Testimonials;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({

  root: {
    maxWidth: 345,
    margin: 'auto',
  },
  titleStyle:{
    color: 'skyblue',
    fontFamily: 'Aladin',
    fontSize: '25px',
    fontWeight : '700',
    textShadow: '1px 1px 1px black',
    backgroundColor: 'rgb(159, 171, 179)'
  },
  textStyle:{
    color: 'black',
    fontFamily: 'Aladin',
    bottom: '2px',
  }
});

export default function ImgMediaCard(props) {

  let hWid = window.innerHeight;
  let height = hWid/5;
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <Typography gutterBottom variant="h4" component="h3" className={classes.titleStyle}>
            {props.title}
            <br/>
          </Typography>
          <CardMedia
            component="img"
            height={height}
            image= {props.image}
            title={props.title}
          />
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p" className={classes.textStyle}>
              {props.body}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>

  );
}

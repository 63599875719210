import React from 'react';
import {Container,Col,Row} from 'react-bootstrap';


export default function BannerText(props){
  let wWin = window.innerWidth;
  let hWin = window.innerWidth;
  let size;
  let height = hWin/10;
  if(wWin<350){
    size = 550
  }else{
    size=wWin
  }

  const Styles = {
    minHeight: height,
    paddingTop: height/4,
    color: 'white',
    backgroundColor: 'skyblue',
  }

  return(
    <div style={Styles} className='bannerText'>
      <h1>{props.text}</h1>
      <br/>
    </div>
  );
};

import React from 'react';
import {Container,Row,Col,Image} from 'react-bootstrap';

import image from '../images/whiterabbit.png';

function About() {
  const Styles = {
    color: 'white'
  }

  const TextStyles = {
    fontFamily: 'Aladin',
    color: 'black'
  }

  return (
    <div className="About">
      <br/>
      <Container>
      <h1>About</h1>
      <br/>
      <Row>
        <Col lg="6">
        <div style={TextStyles}>
          Cheshire Creations is a unique Austin home grown company with an eccentric taste leading to originality and inspirational products.
          We work around the clock to product the content you need with exception results
          <br/>
          <br/>
          <ul>
            <li>Custom Websites Ideal for Startups and Growing Businesses</li>
            <li>State of the Art Technology</li>
            <li>Afforable Rates $</li>
          </ul>
        </div>
        </Col>
        <Col>
          <Image src={image} height = '300px'/>
        </Col>
      </Row>
      <br/>
      </Container>
    </div>
  );
}

export default About;

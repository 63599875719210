import React from 'react';
import {CardGroup,CardDeck, Card, Container, Button} from 'react-bootstrap';
import ImgMediaCard from './ImgMediaCard';


function CardGroupComponent(props) {
  const services = props.services;
  console.log('services', services);

  function renderCardGroup(){
    return props.services.map((service, index)=>{
      return (
        <>
          <ImgMediaCard title={service.title} body={service.body} image={service.image}/>
          <br/>
        </>
      )
    })
  }

  return (
    <>
      <Container>
      <br/>
        <CardDeck>
          {renderCardGroup()}
        </CardDeck>
      </Container>
    </>
  );
}

export default CardGroupComponent;
